.ant-cascader-menu {
    height: auto;
    overflow: hidden;
}

.pdf-upload .anticon.anticon-loading {
    display: none !important;
}

.upload-info {
    display: block;
}

.ant-tabs-bar {
    margin: 30px 0;
}

.steps-content {
    margin-top: 16px;
    border: 1px dashed #e9e9e9;
    border-radius: 6px;
    background-color: #fafafa;
    min-height: 70vh;
    text-align: center;
    padding-top: 2rem;
}

.ant-steps {
    margin-bottom: 1rem;
}

.steps-action {
    margin-top: 24px;
}

.parameters .ant-form-item-label {
    white-space: normal;
}

.parameters .ant-form-item-label label {
    max-width: 250px;
    margin-right: 15px;
    margin-top: 5px;
    line-height: 14px;
}

.parameters .ant-form-item-children {
    width: 100%;
}

.parameters .ant-form-item {
    display: flex;
}

.parameters .ant-form-item-label > label::after  {
    margin: 0;
}

.steps-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
}

.ant-form-item.parameterFormItem {
    display: flex;
}

/* Upload icons */

.ant-upload-list-item-name {
    display: block !important;
    position: absolute;
    bottom: 0;
    background: rgba(255,255,255,0.8);
}

.ant-upload-list-picture-card .ant-upload-list-item-info::before {
    left: 0;
}

.names-container {
    display: flex;
    height: 30px;
}

.names-container .uploadName {
    width: 110px;
    font-size: 14px;
    padding: 0 8px;
    line-height: 14px;
    height: 30px;
    word-break: break-all;
    margin-bottom: 0;
}

a[href*='.pdf'], a[href*='.txt'] .anticon.anticon-eye-o {
    /*display: none;*/
}

/* end of upload icons */

.editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 15px;
}
.export-info {
    text-align: center;
    font-size: 13px;
}

.export-link {
    color: inherit;
    border-color: transparent;
}

.export-link:hover {
    color: inherit;
    border-color: transparent;
    cursor: pointer;
}

.ant-divider-horizontal {
    margin: 15px 0;
}

.ant-table-placeholder {
    min-height: 500px;
    padding-top: 1rem;
}

.ant-table-content {
    min-height: 500px;
}


.ant-card-body {
    padding: 20px 0 0 0;
}

label {
    display: inline-block;
}

label::first-letter {
    text-transform: capitalize;
}

.ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.1);
}